import { Grid } from "@mui/material";
import { useState, useEffect } from "react";

import HandshakeIcon from "@mui/icons-material/Handshake";

export function ColorBox(props) {
	const numArray = ["deal", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
	const [selected, setSelected] = useState(JSON.parse(localStorage.getItem(`${props.color}-selected`)) || []);
	const [deals, setDeals] = useState(JSON.parse(localStorage.getItem(`${props.color}-deals`)) || 1);

	useEffect(() => {
		if (localStorage.getItem(`${props.color}-selected`) !== JSON.stringify(selected)) {
			localStorage.setItem(`${props.color}-selected`, JSON.stringify(selected));
		}
	}, [selected, props.color]);

	useEffect(() => {
		if (localStorage.getItem(`${props.color}-deals`) !== JSON.stringify(deals)) {
			localStorage.setItem(`${props.color}-deals`, JSON.stringify(deals));
		}
	}, [deals, props.color]);

	useEffect(() => {
		if (props.resetSignal) {
			setSelected([]);
			setDeals(1);
		}
	}, [props.resetSignal]);

	function clickNumber(number) {
		if (props.gameOver) {
			props.resetGame();
			return null;
		}
		let newSelected = [...selected];
		if (number !== "deal") {
			if (newSelected.includes(number)) {
				newSelected.splice(newSelected.indexOf(number), 1);
			} else {
				newSelected.push(number);
			}
		} else {
			let dealCount = newSelected.filter((currentElement) => currentElement === "deal").length;

			if (dealCount < 3) {
				newSelected.push(number);
			} else {
				newSelected.splice(newSelected.indexOf(number), 1);
				newSelected.splice(newSelected.indexOf(number), 1);
				newSelected.splice(newSelected.indexOf(number), 1);
			}
			dealCount = newSelected.filter((currentElement) => currentElement === "deal").length;

			if (dealCount === 0) {
				setDeals(1);
			} else {
				setDeals(dealCount);
			}
		}
		setSelected(newSelected);
		props.scoreFeedback(props.color, newSelected);
	}

	return numArray.map((numElement) => {
		return (
			<Grid
				key={`${props.color}-${numElement}`}
				item
				xs={1}
				className={
					props.gameOver
						? "colorDisabled"
						: selected.includes(numElement)
						? `${props.color} selected`
						: props.color
				}
				onClick={() => clickNumber(numElement)}
			>
				<div className="numberContainer">
					{numElement === "deal"
						? Array.from({ length: deals }, (_, i) => <HandshakeIcon key={i} />)
						: numElement}
				</div>
			</Grid>
		);
	});
}
