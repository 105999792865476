import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UndoIcon from "@mui/icons-material/Undo";
import HistoryIcon from "@mui/icons-material/History";
import CalculateIcon from "@mui/icons-material/Calculate";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClearIcon from "@mui/icons-material/Clear";

export default function MenuDrawer(props) {
	return (
		<React.Fragment key="menuDrawer">
			<Drawer anchor={"bottom"} open={props.open} onClose={props.closeDrawer}>
				<Box sx={{ width: "auto" }} role="presentation">
					<List>
						<ListItem key="reset" onClick={props.resetScores}>
							<ListItemButton>
								<ListItemIcon>
									<UndoIcon />
								</ListItemIcon>
								<ListItemText primary="Reset current round" />
							</ListItemButton>
						</ListItem>
						<ListItem key="gameReset" onClick={props.deleteFunction}>
							<ListItemButton>
								<ListItemIcon>
									<HistoryIcon />
								</ListItemIcon>
								<ListItemText primary="Reset game" />
							</ListItemButton>
						</ListItem>
					</List>
					<Divider />
					<List>
						<ListItem key="breakdown" onClick={props.openDetails}>
							<ListItemButton>
								<ListItemIcon>
									<CalculateIcon />
								</ListItemIcon>
								<ListItemText primary="Detailed score info" />
							</ListItemButton>
						</ListItem>
						<ListItem key="roundsOverview" onClick={props.openRecap}>
							<ListItemButton>
								<ListItemIcon>
									<FormatListBulletedIcon />
								</ListItemIcon>
								<ListItemText primary="Show previous rounds" />
							</ListItemButton>
						</ListItem>
					</List>
					<Divider />
					<List>
						<ListItem key="Closemenu" onClick={props.closeDrawer}>
							<ListItemButton>
								<ListItemIcon>
									<ClearIcon />
								</ListItemIcon>
								<ListItemText primary="Close menu" />
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</React.Fragment>
	);
}
