import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";

export function RecapDrawer(props) {
	return (
		<React.Fragment key="recapDrawer">
			<Drawer anchor={"bottom"} open={props.open} onClose={props.closeDrawer}>
				<Box sx={{ width: "auto" }} role="presentation">
					<List>
						{props.roundScores &&
							Object.keys(props.roundScores).map((round) => {
								return (
									<ListItem key={`round${round}-breakdown`}>
										<ListItemText primary={`Round ${parseInt(round) + 1}:`} />
										<ListItemText
											style={{ textAlign: "right" }}
											primary={props.roundScores[round]["total"]}
										/>
									</ListItem>
								);
							})}
						<Divider />

						<ListItem key="roundtotal">
							<ListItemText primary="Grand total" />

							<ListItemText primary={props.totalScore} style={{ textAlign: "right", margin: "0px" }} />
						</ListItem>
						<ListItem key="Closemenu" onClick={props.closeDrawer}>
							<ListItemButton>
								<ListItemIcon>
									<ClearIcon />
								</ListItemIcon>
								<ListItemText primary={"Close recap"} />
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</React.Fragment>
	);
}
