import { Button, Grid, Stack } from "@mui/material";
import "./App.css";
import { ColorBox } from "./colorBox.comp.js";
import { useState, useEffect } from "react";
import MenuDrawer from "./menuDrawer.comp";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ConfirmDeleteDialog from "./confirmDelete.comp";
import { DetailDrawer } from "./detailDrawer.comp";
import { RecapDrawer } from "./recapDrawer";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

const startingScores = { Red: 0, Blue: 0, White: 0, Yellow: 0, Green: 0 };

function App() {
	const colors = ["White", "Red", "Blue", "Green", "Yellow"];
	const [scores, setScores] = useState(JSON.parse(localStorage.getItem("resArray")) || startingScores);
	const [scoreBreakdown, setScoreBreakdown] = useState(JSON.parse(localStorage.getItem("scoreBreakdown")) || {});
	const [totalScore, setTotalScore] = useState(JSON.parse(localStorage.getItem("totalScore")) || 0);
	const [roundScore, setRoundScore] = useState(JSON.parse(localStorage.getItem("roundScore")) || 0);
	const [menuOpen, setMenuOpen] = useState(false);
	const [resetSignal, setResetSignal] = useState(false);
	const rounds = 3;
	const [currentRound, setCurrentRound] = useState(JSON.parse(localStorage.getItem("currentRound")) || 0);
	const [roundScores, setRoundScores] = useState(JSON.parse(localStorage.getItem("roundScores")) || {});
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [detailDrawerOpen, setDetailDrawerOpen] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [recapOpen, setRecapOpen] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("scores") !== JSON.stringify(scores)) {
			localStorage.setItem("scores", JSON.stringify(scores));
		}
	}, [scores]);

	useEffect(() => {
		if (localStorage.getItem("scoreBreakdown") !== JSON.stringify(scoreBreakdown)) {
			localStorage.setItem("scoreBreakdown", JSON.stringify(scoreBreakdown));
		}
	}, [scoreBreakdown]);

	useEffect(() => {
		if (localStorage.getItem("totalScore") !== JSON.stringify(totalScore)) {
			localStorage.setItem("totalScore", JSON.stringify(totalScore));
		}
	}, [totalScore]);

	useEffect(() => {
		if (localStorage.getItem("roundScore") !== JSON.stringify(roundScore)) {
			localStorage.setItem("roundScore", JSON.stringify(roundScore));
		}
	}, [roundScore]);

	useEffect(() => {
		if (localStorage.getItem("currentRound") !== JSON.stringify(currentRound)) {
			localStorage.setItem("currentRound", JSON.stringify(currentRound));
		}
	}, [currentRound]);

	useEffect(() => {
		if (localStorage.getItem("roundScores") !== JSON.stringify(roundScores)) {
			localStorage.setItem("roundScores", JSON.stringify(roundScores));
		}
	}, [roundScores]);

	function getScores(color, selected) {
		let score = 0;
		let newScores = { ...scores };
		let breakDown = { ...scoreBreakdown };
		if (selected.length > 0) {
			breakDown[color] = { "Expedition Started": -20 };
			score = -20;
			selected.forEach((selectedNum) => {
				if (selectedNum !== "deal") score += parseInt(selectedNum);
			});
			breakDown[color]["Points scored"] = score + 20;
			const dealCount = 1 + selected.filter((currentElement) => currentElement === "deal").length;
			breakDown[color]["Sub total"] = score;

			score *= dealCount;
			breakDown[color]["Multiplier"] = dealCount;
			if (selected.length >= 8) {
				score += 20;
				breakDown[color]["8+ Card bonus"] = 20;
			}
			breakDown[color][color + " total"] = score;
		} else {
			delete breakDown[color];
		}
		setScoreBreakdown(breakDown);
		newScores[color] = score;
		setScores(newScores);
	}

	useEffect(() => {
		let roundScore = Object.keys(scores).reduce(function (previous, key) {
			return previous + scores[key];
		}, 0);
		setRoundScore(roundScore);
	}, [scores, roundScores]);

	function resetScores() {
		setResetSignal(true);
		setMenuOpen(false);
		setScores({ red: 0, blue: 0, white: 0, yellow: 0, green: 0 });
		setScoreBreakdown({});
		setShowConfirm(false);
	}
	useEffect(() => {
		if (resetSignal) {
			setResetSignal(false);
		}
	}, [resetSignal]);

	function submitRound() {
		let newRoundScores = { ...roundScores };
		newRoundScores[currentRound] = { total: roundScore, breakDown: scores };
		let totalScore = Object.keys(newRoundScores).reduce(function (previous, key) {
			return previous + newRoundScores[key].total;
		}, 0);
		setRoundScores(newRoundScores);
		setTotalScore(totalScore);
		setCurrentRound(currentRound + 1);
		resetScores();
	}

	function deleteConfirm() {
		setRoundScores({});
		resetScores();
		setDeleteOpen(false);
		setMenuOpen(false);
		setCurrentRound(0);
		setTotalScore(0);
	}

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<>
				<ConfirmDeleteDialog
					dialogOpen={deleteOpen}
					confirmFunction={deleteConfirm}
					cancelFunction={() => {
						setDeleteOpen(false);
					}}
				/>
				<div className="mainContainer">
					<Grid className="gridContainer" container spacing={1} columns={5}>
						{colors.map((color) => {
							return (
								<ColorBox
									resetSignal={resetSignal}
									color={color}
									scoreFeedback={getScores}
									key={`${color}-grid`}
									gameOver={currentRound === rounds}
									resetGame={() => {
										setDeleteOpen(true);
									}}
								/>
							);
						})}
					</Grid>
					<div className="buttonContainer">
						<Stack>
							<div className="roundHeader">
								{currentRound === rounds ? "GAME OVER" : `ROUND ${currentRound + 1} OF ${rounds}`}
							</div>
							<div>{`TOTAL: ${totalScore}`}</div>
						</Stack>

						<Button
							variant="outlined"
							onClick={() => {
								setMenuOpen(true);
							}}
						>
							Menu
						</Button>

						<Button
							disabled={Object.keys(scoreBreakdown).length === 0 && currentRound !== rounds}
							onClick={() => {
								if (currentRound === rounds) {
									setDeleteOpen(true);
								} else {
									setShowConfirm(true);
									setDetailDrawerOpen(true);
								}
							}}
							className="submitButton"
						>
							{currentRound === rounds ? "new game" : "end round"}
						</Button>
					</div>
				</div>
				<DetailDrawer
					open={detailDrawerOpen}
					closeDrawer={() => {
						setDetailDrawerOpen(false);
						setShowConfirm(false);
					}}
					breakdown={scoreBreakdown}
					roundScore={roundScore}
					submitState={showConfirm}
					submitFunction={submitRound}
				/>
				<MenuDrawer
					open={menuOpen}
					closeDrawer={() => {
						setMenuOpen(false);
					}}
					resetScores={resetScores}
					deleteFunction={() => {
						setDeleteOpen(true);
					}}
					openDetails={() => {
						setDetailDrawerOpen(true);
					}}
					openRecap={() => {
						setRecapOpen(true);
					}}
				/>
				<RecapDrawer
					open={recapOpen}
					closeDrawer={() => {
						setRecapOpen(false);
					}}
					totalScore={totalScore}
					roundScores={roundScores}
				/>
			</>
		</ThemeProvider>
	);
}

export default App;
