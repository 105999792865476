import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";

export function DetailDrawer(props) {
	return (
		<React.Fragment key="detailDrawer">
			<Drawer anchor={"bottom"} open={props.open} onClose={props.closeDrawer}>
				<Box sx={{ width: "auto" }} role="presentation">
					{props.breakdown &&
						Object.keys(props.breakdown).map((color) => {
							return (
								<List key={`${color}-Text`} className={`${color}Text`}>
									{props.breakdown[color] &&
										Object.keys(props.breakdown[color]).map((key, index) => {
											return (
												<React.Fragment key={`${key}-${index}`}>
													<ListItem
														style={{ paddingTop: "0px", paddingBottom: "0px" }}
														key={`${key}-breakdown`}
													>
														<ListItemText primary={key} style={{ margin: "0px" }} />
														<ListItemText
															style={{ textAlign: "right", margin: "0px" }}
															primary={props.breakdown[color][key]}
														/>
													</ListItem>
													{index === 2 && <Divider />}
												</React.Fragment>
											);
										})}
								</List>
							);
						})}

					<List>
						<ListItem key="roundtotal">
							<ListItemButton>
								<ListItemText primary="Round total" />

								<ListItemText
									primary={props.roundScore}
									style={{ textAlign: "right", margin: "0px" }}
								/>
							</ListItemButton>
						</ListItem>
						<ListItem key="Closemenu" onClick={props.closeDrawer}>
							<ListItemButton>
								<ListItemIcon>
									<ClearIcon />
								</ListItemIcon>
								<ListItemText primary={props.submitState ? "Cancel" : "Close overview"} />
							</ListItemButton>
							{props.submitState && (
								<ListItemButton onClick={props.submitFunction}>
									<ListItemIcon>
										<DoneIcon />
									</ListItemIcon>
									<ListItemText primary="Submit Round" />
								</ListItemButton>
							)}
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</React.Fragment>
	);
}
